import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store from '@/store';
import { sendSms, smsInfo, mobilePhone, callout, callNum } from '@/api/ceshi_xiugai/whole';
import { sendmsg, billcustomer, getQiYuIpcc } from '@/api/login';
export default {
  data: function data() {
    return {
      gridData: [],
      msgData: [],
      rowData: {},
      dialogTableVisible: false,
      confirmLoading: false,
      form: {
        date1: '',
        textarea1: ''
      },
      alwaysTextarea: '',
      formLabelWidth: '120px',
      dialogVisible: false,
      selectedMobile: [],
      selectedMobileCall: {},
      isOneMobile: true,
      //是否只有一个电话
      whichMethod: '0',
      // 1 工作电话  2 系统外呼
      id: '',
      customer_id: '',
      cno: '',
      disabledSms: false,
      autoSelectedTableIndex: 0 //用于标识是否为首次默认选中手机号
    };
  },
  watch: {},
  mounted: function mounted() {},
  components: {},
  methods: {
    handleSelectionChange: function handleSelectionChange(val) {
      if (this.autoSelectedTableIndex != 0) {
        this.selectedMobile = val;
      }
      this.autoSelectedTableIndex++;
    },
    // 隐藏表头中的全选框
    cellClass: function cellClass(row) {
      if (row.columnIndex === 0) {
        return 'disabledCheck';
      }
    },
    checkSelectable: function checkSelectable(row) {
      if (row.is_optional == 0) {
        return true;
      }
      return false;
    },
    handleRowChange: function handleRowChange(row) {
      this.selectedMobileCall = row;
    },
    formatTimeMills: function formatTimeMills(timeMills) {
      var date = new Date(timeMills);
      var timeStr = date.getFullYear() + '-';
      if (date.getMonth() < 9) {
        // 月份从0开始的
        timeStr += '0';
      }
      timeStr += date.getMonth() + 1 + '-';
      timeStr += date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      timeStr += ' ';
      timeStr += date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      timeStr += ':';
      timeStr += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      timeStr += ':';
      timeStr += date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      return timeStr;
    },
    // 发送短信事件
    isSendOrShowFun: function isSendOrShowFun(row) {
      var _this = this;
      this.form.date1 = this.formatTimeMills(new Date().getTime());
      this.customer_id = row.customer_id;
      // 选中满足条件的第一个手机号码
      smsInfo({
        customer_id: this.customer_id
      }).then(function (res) {
        _this.selectedMobile = [];
        _this.dialogTableVisible = true;
        _this.msgData = res.data.mobile_list;
        _this.autoSelectedTableIndex = 0;
        _this.alwaysTextarea = res.data.sms_content;
        var strOld = _this.form.date1;
        strOld = strOld.replace('-', '年');
        strOld = strOld.replace('-', '月');
        strOld = strOld.replace(' ', '日');
        strOld = strOld.replace(':', '时');
        strOld = strOld.replace(':', '分');
        strOld = strOld.slice(0, strOld.length - 2);
        var str = _this.alwaysTextarea.replace('xxx', strOld);
        _this.form.textarea1 = str;
        var num = 0;
        _this.msgData.forEach(function (val) {
          if (val.is_optional == 1) {
            num++;
          }
        });
        var _loop = function _loop(i) {
          if (_this.msgData[i].is_optional == 0) {
            _this.selectedMobile.push(_this.msgData[i]);
            _this.$nextTick(function () {
              _this.$refs.multipleTable.toggleRowSelection(_this.msgData[i]);
            });
            return 1; // break
          }
        };
        for (var i = 0; i < _this.msgData.length; i++) {
          if (_loop(i)) break;
        }
        if (num == _this.msgData.length) {
          _this.disabledSms = true;
        } else {
          _this.disabledSms = false;
        }
      }).catch(function (err) {
        _this.confirmLoading = false;
      });
    },
    // 发送短信确定按钮
    sendMsgFun: function sendMsgFun() {
      var _this2 = this;
      var arr = [];
      this.selectedMobile.forEach(function (val) {
        arr.push(val.field);
      });
      if (this.form.date1 == '') {
        this.$message({
          message: '预约回电时间不能为空',
          type: 'warning'
        });
        return;
      }
      if (arr.length == 0) {
        this.$message({
          message: '请选择接收短信电话',
          type: 'warning'
        });
        return;
      }
      this.confirmLoading = true;
      var params = {
        customer_id: this.customer_id,
        reserve_callback_time: this.form.date1,
        fields: arr.toString()
      };
      sendSms(params).then(function (res) {
        _this2.confirmLoading = false;
        _this2.dialogTableVisible = false;
        // 更新列表或详情
        _this2.$emit('onUpdateQueryFun');
      }).catch(function (err) {
        _this2.confirmLoading = false;
      });
    },
    // ------------------外呼-----------------------
    mobilePhoneFun: function mobilePhoneFun(customer_id, mobile_column) {
      mobilePhone({
        customer_id: customer_id,
        mobile_column: mobile_column
      }).then(function (res) {}).catch(function (res) {});
    },
    // 系统外呼
    OutboundClick: function OutboundClick(row, type) {
      var _this3 = this;
      var cno = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      this.cno = cno;
      if (type == 'workphone') {
        this.whichMethod = 1;
      } else {
        this.whichMethod = 2;
      }
      this.rowData = JSON.parse(JSON.stringify(row));
      this.id = this.rowData.mobile;
      this.gridData = [{
        id: 1,
        tele: this.rowData.mobile,
        field: 'mobile'
      }];
      this.selectedMobileCall = {
        id: 1,
        tele: this.rowData.mobile,
        field: 'mobile'
      };
      this.isOneMobile = true;
      if (this.rowData.mobile1) {
        this.gridData.push({
          id: 2,
          tele: this.rowData.mobile1,
          field: 'mobile1'
        });
        this.isOneMobile = false;
      }
      if (this.rowData.mobile2) {
        this.gridData.push({
          id: 3,
          tele: this.rowData.mobile2,
          field: 'mobile2'
        });
        this.isOneMobile = false;
      }
      // 判断客户是否有多个电话 如果有多个电话出现弹窗选择
      // 如果只有一个电话 保持原有操作不变
      // 如果是系统外呼
      if (this.whichMethod == 2) {
        var tian = localStorage.getItem('Clink');
        if (tian == 'BUSY') {
          this.$notify({
            title: '警告',
            message: '系统外呼正在通话中，请结束通话后再使用工作手机外呼',
            type: 'warning'
          });
          return;
        }
      }
      if (!this.isOneMobile) {
        // 弹出选择框
        this.dialogVisible = true;
      } else {
        if (this.whichMethod == 1) {
          this.$confirm('使用工作手机直接外呼，确定呼叫？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            type: 'warning'
          }).then(function () {
            _this3.mobilePhoneFun(row.customer_id, _this3.selectedMobileCall.field);
          }).catch(function () {});
        } else {
          this.callOutFun(this.cno, row, this.selectedMobileCall.field);
        }
      }
    },
    callOutFun: function callOutFun(cno, row) {
      var _this4 = this;
      callNum({
        customer_id: row.customer_id,
        mobile_column: this.selectedMobileCall.field
      }).then(function (res) {
        var content = res.data.content;
        if (content == '') {
          _this4.calloutFun1(cno, row.customer_id, _this4.selectedMobileCall.field);
        } else {
          _this4.$confirm(content, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            type: 'warning'
          }).then(function () {
            _this4.calloutFun1(cno, row.customer_id, _this4.selectedMobileCall.field);
          }).catch(function () {});
        }
      });
    },
    calloutFun1: function calloutFun1(cno, customer_id, mobile_column) {
      // callout({
      //     cno: cno,
      //     mobile_column,
      //     customer_id
      // }).then((res) => {
      //     // _this.$router.push({ path: '/BasicOperation/customerManagement/callCenter' });

      //     
      // });

      console.log('cno, customer_id, mobile_column', cno, customer_id, mobile_column);
      this.tianrun(this.selectedMobileCall.tele);
    },
    // 初始化网易七鱼sdk
    initQiyuSdk: function initQiyuSdk(url) {
      var sdk = document.createElement('script');
      var mobile = this.selectedMobileCall.tele;
      sdk.async = !0;
      sdk.src = url;
      sdk.onload = function () {
        //sdk加载成功后，注册呼叫工具条事件
        qiConnect.on({
          onload: function onload() {
            //呼叫工具条加载完毕的事件通知。此事件完成后才可调用外呼接口
            console.log('呼叫工具条加载完毕！');
            qiConnect.keepPanelUnfold(true);
          },
          // 工具条初始化完成后，可以设置状态
          initSuccess: function initSuccess() {
            qiConnect.setStatus([qiConnect.statusOptions[0].value]);
            console.log('mobile', mobile);
            setTimeout(function () {
              qiConnect.call(mobile);
            }, 1000);
          },
          /**
           * 建立新呼叫会话的事件，包括外呼和呼入
           * @param  {Object} session  呼叫会话的session信息
           *
           * @param  {String} session.address      号码归属地
           * @param  {String} session.usernumber   客户号码
           * @param  {Number} session.sessionid    会话id
           * @param  {String} session.staffnumber  坐席号码
           * @param  {Number} session.staffid      坐席id
           * @param  {String} session.staffname    坐席账号
           * @param  {Number} session.direction    会话方向，1表示呼入，2表示呼出
           */
          session: function session(_session) {
            console.log('session', _session);
          }
        });
        window.onbeforeunload = function () {
          if (typeof qiConnect !== 'undefined') {
            qiConnect.bye();
          }
        };
      };
      document.body.appendChild(sdk);
    },
    // zzy天润
    tianrun: function tianrun(val) {
      var _this5 = this;
      if (typeof qiConnect !== 'undefined') {
        if (window.isCalling) return;
        if (this.callingFlag) return;
        this.callingFlag = true;
        setTimeout(function () {
          _this5.callingFlag = false;
        }, 2000);
        qiConnect.keepPanelUnfold(true);
        qiConnect.call(val);
      }
      return;
      this.initialized = true;
      var params = {};
      var stose = store.getters.TianRun;
      if (stose.bindTel != '') {
        var params = {};
        params.identifier = stose.identifier; //编号
        params.cno = stose.bindTel; // 坐席号
        params.loginStatus = 1;
        params.bindTel = stose.bindTel; // 编号、手机号
        params.bindType = stose.bindType; // 类型
        params.password = stose.password; // 密码

        // 获取网易七鱼sdk
        getQiYuIpcc('zklm' + stose.cno).then(function (res) {
          console.log('res', res);
          _this5.initQiyuSdk(res.data.result.sdk_url);
        });
      }
    },
    makeCallFun: function makeCallFun() {
      if (this.whichMethod == 1) {
        this.mobilePhoneFun(this.rowData.customer_id, this.selectedMobileCall.field);
      } else if (this.whichMethod == 2) {
        this.callOutFun(this.cno, this.rowData, this.selectedMobileCall.field);
      }
      this.dialogVisible = false;
    },
    changeSmsInfoFun: function changeSmsInfoFun(val) {
      var strOld = val;
      strOld = strOld.replace('-', '年');
      strOld = strOld.replace('-', '月');
      strOld = strOld.replace(' ', '日');
      strOld = strOld.replace(':', '时');
      strOld = strOld.replace(':', '分');
      strOld = strOld.slice(0, strOld.length - 2);
      var str = this.alwaysTextarea.replace('xxx', strOld);
      this.form.textarea1 = str;
    }
  }
};