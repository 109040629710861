import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addWeChatFriend } from '@/api/ceshi_xiugai/whole';
export default {
  props: {
    rowData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  watch: {},
  data: function data() {
    return {
      visisedbles: false,
      gridData: [],
      selectedMobile: [],
      disabledWechat: false
    };
  },
  mounted: function mounted() {},
  components: {},
  methods: {
    showTableFun: function showTableFun() {
      var _this = this;
      this.gridData = [{
        id: 1,
        tele: this.rowData.mobile,
        field: 'mobile'
      }];
      this.$nextTick(function () {
        _this.$refs.multipleTable.toggleRowSelection(_this.gridData[0]);
      });
      this.selectedMobile.push({
        id: 1,
        tele: this.rowData.mobile,
        field: 'mobile'
      });
      if (this.rowData.mobile1) {
        this.gridData.push({
          id: 2,
          tele: this.rowData.mobile1,
          field: 'mobile1'
        });
      }
      if (this.rowData.mobile2) {
        this.gridData.push({
          id: 3,
          tele: this.rowData.mobile2,
          field: 'mobile2'
        });
      }
    },
    hideTableFun: function hideTableFun() {
      this.selectedMobile = [];
      this.rowData.message = '';
      this.rowData.remark = this.rowData.cname;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectedMobile = val;
    },
    // 隐藏表头中的全选框
    cellClass: function cellClass(row) {
      if (row.columnIndex === 0) {
        return 'disabledCheck';
      }
    },
    checkSelectable: function checkSelectable(row) {
      // if (row.id == 1) {
      //     return true;
      // }
      return true;
    },
    // 添加微信
    AddWechat: function AddWechat(row) {
      var _this2 = this;
      this.visisedbles = true;
      var arr = [];
      this.selectedMobile.forEach(function (val) {
        arr.push(val.field);
      });
      if (arr.length == 0) {
        this.$message({
          message: '请选择添加的好友',
          type: 'warning'
        });
        this.visisedbles = false;
        return;
      }
      var params = {
        wechat_account_id: row.wechat_account_id,
        // friend_wechat: arr,
        mobile_column: arr.toString(),
        message: row.message,
        remark: row.remark,
        customer_id: row.customer_id.toString()
      };
      addWeChatFriend(params).then(function (res) {
        row.message = '';
        row.remark = '';
        _this2.visisedbles = false;
        document.body.click();
        // 更新列表
        _this2.$emit('onUpdateQueryFun');
      }).catch(function (err) {
        row.message = '';
        row.remark = '';
        _this2.visisedbles = false;
        document.body.click();
      });
    },
    CancelAdding: function CancelAdding() {
      document.body.click();
    },
    // 子组件更新父组件的值
    changeInputValueFun: function changeInputValueFun(val, key) {
      // this.$emit('onUpdateDataFun', val, key);
    }
  }
};